/* global tindie, ko, ga */

tindie.messaging = (function(t, $) {
    var aj = tindie.ajax;
    t.newMessageUrl = "/accounts/messages/new";
    t.lock = ko.observable(false);
    t.TindieMessage = function(appendPageName, pageName) {
        var self = this;
        self.username = ko.observable();
        self.displayName = ko.observable();
        self.subject = ko.observable().extend({
            required: {
                message: 'Please enter a subject.'
            }
        });
        self.message = ko.observable().extend({
            required: {
                message: 'Please enter a message.'
            }
        });
        self.prepareForm = function(data, event) {
            self.displayName($(event.target).data('display-name'));
            self.username($(event.target).data('user'));
            if (window.ga && ga.create) {
                ga('send', 'event', pageName, 'click', pageName);
            }
        };
        self.send = function() {
            var messageAppend = "";
            if (appendPageName) {
                messageAppend = " [Sent from " + pageName + "]";
            }
            var data = {
                username: self.username(),
                subject: self.subject().trim(),
                message: self.message().trim() + messageAppend,
            };
            if (t.lock()) {
                // doesn't this just discard the update? XXX
                return $.Deferred();
            }
            t.lock(true);
            return $.ajax({
                type: 'POST',
                url: t.newMessageUrl,
                dataType: 'json',
                data: data
            }).done(function(result) {
                if (result.django_messages) {
                    aj.show_notifications(result.django_messages);
                }
                t.lock(false);
                $('#id-sendpm-modal').modal('hide');
            }).fail(function(jqXHR, status, error) {
                switch (jqXHR.status) {
                    case 503:
                        aj.notify_message({
                            message: aj.maintenanceMessage,
                            type: 'error'
                        });
                        break;
                    default:
                        console.log(jqXHR.status);
                        aj.notify_message({
                            message: aj.errorMessage,
                            type: 'error'
                        });
                        break;
                }
                t.lock(false);
            });
        };
    };
    return t;
})(tindie.messaging || {}, jQuery);