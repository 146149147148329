/* global tindie, ko, ga, Waypoint */

tindie.stores.detail = (function(t, $) {
    var products = tindie.productUtils,
        aj = tindie.ajax,
        CurrencyInfo = tindie.app.CurrencyInfo,
        shippingRatesUrl = $("#store-section-shipping").data("shipping-rates-url"),
        messaging = tindie.messaging;
    var sendImpressions = function() {
        if (window.ga && ga.create) {
            ga('send', 'event', 'Store Detail Page', 'Send Product Impressions', {
                nonInteraction: true,
                transport: 'beacon'
            });
        }
    };
    var set_waypoint = function(el_id) {
        new Waypoint({
            element: document.getElementById(el_id),
            handler: function(direction) {
                if (window.ga && ga.create) {
                    ga('send', 'event', 'Store Page', 'Reached ' + el_id, 'Q2 2018', {
                        nonInteraction: true
                    });
                }
                this.destroy();
            },
            offset: 20
        });
    };
    var getShippingRates = function(view) {
        var data = {
            country: view.country()
        };
        aj.ajax_update_viewmodel(shippingRatesUrl, data, view, null, function(result) {
            ko.mapping.fromJS(result, {
                currencyInfo: {
                    update: function(options) {
                        return new CurrencyInfo(view, options.data);
                    }
                },
                shippingRates: {
                    create: function(options) {
                        return new ShippingRate(view, options.data);
                    }
                }
            }, view);
            view.changeCountry(false);
        });
    };
    var ShippingRate = function(view, data) {
        var self = this;
        ko.mapping.fromJS(data, {}, self);
        self.hasTracking = ko.computed(function() {
            return self.tracked() ? "Yes" : "No";
        }, self);
    };
    var StoreDetail = function() {
        var self = this;
        tindie.stores.view.call(self);
        self.store = ko.observable();
        self.storeSeller = ko.observable();
        self.storeProducts = ko.observableArray();
        self.shippingRates = ko.observableArray([]);
        self.changeCountry = ko.observable(false).toggleable();
        getShippingRates(self);
        self.country.subscribe(function() {
            if (self.hasCountry()) {
                getShippingRates(self);
            }
        });
        self.hasShippingRates = ko.computed(function() {
            return (self.hasCountry() && self.shippingRates().length > 0);
        });
        self.sortedShippingRates = ko.computed(function() {
            var sortedRates = self.shippingRates().sort(function(left, right) {
                return left.baseRate === right.baseRate() ? 0 : left.baseRate < right.baseRate() ? 1 : -1;
            });
            return sortedRates;
        });
        self.selectedCountry = ko.computed(function() {
            return (self.hasShippingRates() && !self.changeCountry());
        });
        self.createProductCard = function() {
            return products.createProductCard('Store Detail Page', self.storeProducts);
        };
        self.messageForm = ko.validatedObservable(new messaging.TindieMessage(true, 'Store Page'));

        self.canSendMessage = ko.computed(function() {
            return !aj.lock() && self.messageForm.isValid();
        });
        self.prepAndSendMessage = function() {
            if (self.canSendMessage()) {
                self.messageForm().send();
            }
        };
    };
    t.init = function() {
        var vm = new StoreDetail();
        ko.applyBindings(vm, document.body);
        if ($('.store-details-nav').length > 0) {
            $('body').scrollspy({
                target: '#store-details-nav'
            });
            $('.store-details-nav').affix({
                offset: {
                    top: $('.store-details-nav').offset().top
                }
            });
        }
        var sections = ['store-section-products', 'store-section-about', 'store-section-reviews'];
        $.each(sections, function(k, el_id) {
            if ($('#' + el_id).length > 0) {
                set_waypoint(el_id);
            }
        });
        window.addEventListener('beforeunload', sendImpressions, false);
    };
    return t;
})(tindie.stores.detail || {}, jQuery);