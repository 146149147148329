tindie.func = (function(t, $) {
	ko.observable = ko.observable || {};
	ko.observable.fn = ko.observable.fn || {};
	ko.observable.fn.toggleable = function() {
		var self = this;
		self.toggle = function() {
			self(!self());
		};
		return self;
	};
	ko.observable.fn.lazyLoadable = function() {
		var self = this;
		self.loaded = ko.observable(false);
		self.image = ko.observable();
		self.load = function(src, srcset, hasSrcset) {
			hasSrcset = typeof hasSrcset === 'undefined' ? false : hasSrcset;
			var img = new Image();
			img.onload = function() {
				self.image(img);
				self.loaded(true);
			};
			if (hasSrcset) {
				img.srcset = srcset;
				img.src = src;
			} else {
				img.src = src;
			}
		};
		return self;
	};
	return t;
})(tindie.func || {}, jQuery);