tindie.stores = (function(t, $) {
	t.lock = true;
	t.view = function(vm) {
		var self = this;
		tindie.app.base.call(self);
	};
	t.init = function() {
		var page = tindie.app.getPage();
		tindie.stores[page] != undefined && tindie.stores[page].init();
	}
	return t;
})(tindie.stores || {}, jQuery);