tindie.productUtils = (function(t, $) {
	var trendingUrl = '/fetch/trending/',
		recentlyViewedUrl = '/fetch/recently-viewed/',
		recommendedUrl = '/fetch/recommended/',
		complementaryUrl = '/fetch/complementary/',
		wishlistUrl = '/fetch/wishlist/',
		savedListUrl = '/fetch/saved/';
	t.productCard = function(parentArray, data) {
		var self = this;
		self.seen = ko.observable(false);
		self.id = ko.observable();
		self.title = ko.observable();
		self.hasSale = ko.observable();
		self.isRetired = ko.observable();
		self.shippingPrice = ko.observable();
		self.removeUrl = ko.observable();
		self.category = ko.observable();
		self.thumb = ko.observable();
		self.url = ko.observable();
		self.vendor = ko.observable();
		self.price = ko.observable();
		self.salePrice = ko.observable();
		self.list = ko.observable();
		self.position = ko.observable();
		self.googleCategory = ko.observable();
		if (data) {
			ko.mapping.fromJS(data, {}, self);
		}
		self.canRemove = ko.computed(function() {
			return self.removeUrl() != "";
		});
		self.hasFreeShipping = ko.computed(function() {
			return self.shippingPrice() == "0.00" && !self.isRetired();
		});
		self.removeSubmit = function() {
			return true;
		};
		self.trackProduct = function(data, event) {
			if (window.ga && ga.create) {
				ga("ec:addProduct", {
					id: "P-" + self.id(),
					name: self.title(),
					category: self.googleCategory(),
					brand: self.vendor(),
					//'variant': 'black',
					position: self.position()
				});
				ga("ec:setAction", "click", {
					list: self.list()
				});
				// Send click with an event, then send user to product page.
				ga("send", "event", "UX", "click", "Results", {
					transport: "beacon"
				});
			}
			return true;
		};
		self.addAnalyticsImpression = function() {
			if (window.ga && ga.create) {
				ga("ec:addImpression", {
					id: "P-" + self.id(),
					name: self.title(),
					category: self.googleCategory(),
					brand: self.vendor(),
					//'variant': 'card', //for when we have options?
					list: self.list(),
					position: self.position()
				});
			}
		};
		self.id.subscribe(function() {
			if (self.id() != "" && !self.seen()) {
				self.seen(true);
				var isTrueRetired = self.isRetired() == "true";
				self.isRetired(isTrueRetired);
				var isTrueSale = self.hasSale() == "true";
				self.hasSale(isTrueSale);
				self.position(parentArray.indexOf(self) + 1);
				self.addAnalyticsImpression();
			}
		});
	};
	t.createProductCard = function(list, parentArray, data) {
		var p = new t.productCard(parentArray, data);
		p.list(list);
		return p;
	};
	t.getTrendingProducts = function(numProducts, parentArray, list) {
		var url = trendingUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.trendingProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.trendingProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.getRecentlyViewedProducts = function(numProducts, parentArray, list) {
		var url = recentlyViewedUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.recentlyViewedProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.recentlyViewedProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.getRecommendedProducts = function(numProducts, parentArray, list) {
		var url = recommendedUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.recommendedProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.recommendedProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.getComplementaryProducts = function(numProducts, parentArray, list) {
		var url = complementaryUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.complementaryProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.complementaryProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.getWishlistProducts = function(numProducts, parentArray, list) {
		var url = wishlistUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.wishlistProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.wishlistProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	t.getSavedProducts = function(numProducts, parentArray, list) {
		var url = savedListUrl + numProducts + '/';
		$.get(url).done(function(response) {
			if (response.success == true) {
				for (var i = 0; i < response.savedProducts.length; i++) {
					p = t.createProductCard(list, parentArray, response.savedProducts[i]);
					parentArray.push(p);
				}
			}
		});
	};
	return t;
})(tindie.productUtils || {}, jQuery);